@import url("https://www.dafontfree.net/embed/bm90by1zYW5zLWJvbGQmZGF0YS8yMS9uLzEwMDM1MS9Ob3RvU2Fucy1Cb2xkLnR0Zg");

* {
  margin: 0;
  padding: 0;
  font-family: "noto-sans", sans-serif;
}

section {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover !important;
  background: url(https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.box {
  position: relative;
  width: 750px;
  min-width: 400px;

  #square0 {
    animation: square 5s linear infinite;
    animation-delay: calc(-1s * 0);
    width: 100px;
    height: 100px;
    top: -15px;
    right: -45px;
  }
  #square1 {
    animation: square 5s linear infinite;
    animation-delay: calc(-1s * 1);
    width: 150px;
    height: 150px;
    top: 105px;
    left: -125px;
    z-index: 2;
  }
  #square2 {
    animation: square 5s linear infinite;
    animation-delay: calc(-1s * 2);
    width: 60px;
    height: 60px;
    bottom: 85px;
    right: -45px;
    z-index: 2;
  }
  #square3 {
    animation: square 5s linear infinite;
    animation-delay: calc(-1s * 3);
    width: 50px;
    height: 50px;
    bottom: 35px;
    left: -95px;
  }
  #square4 {
    animation: square 5s linear infinite;
    animation-delay: calc(-1s * 4);
    width: 50px;
    height: 50px;
    top: -15px;
    left: -25px;
  }
  #square5 {
    animation: square 5s linear infinite;
    animation-delay: calc(-1s * 5);
    width: 85px;
    height: 85px;
    top: 165px;
    right: -155px;
    z-index: 2;
  }

  .square {
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 15px;
  }

  @keyframes square {
    0%,
    100% {
      transform: translateY(-20px);
    }

    50% {
      transform: translateY(20px);
    }
  }
}

.container {
  position: relative;
  padding: 50px;
  width: 100%;
  min-height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.2);
}

.container::after {
  content: "";
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border-radius: 5px;
  pointer-events: none;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 2%
  );
}

.form {
  width: 95%;
  height: 100%;
  position: relative;

  h4, h3 {
    background: -webkit-linear-gradient(
      0deg,
      #ee7752,
      #e73c7e,
      #23a6d5,
      #23d5ab
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1px;
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .inputBx {
    position: relative !important;
    width: 100%;
    background-color: red;

    input {
      width: 100%;
      outline: none;
      border: none;
      margin-top: 5px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background: rgba(255, 255, 255, 0.2);
      // padding: 8px 10px;
      padding-left: 40px;
      border-radius: 5px;
      height: 40px;
      color: #fff;
      font-size: 16px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    }

    span {
      position: absolute;
      left: 20px;
      padding: 10px;
      display: inline-block;
      color: #fff;
      transition: 0.5s;
      pointer-events: none;
    }

    input:focus ~ span,
    input:valid ~ span {
      transform: translateX(-30px) translateY(-25px);
      font-size: 12px;
    }
  }
  .linksRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  p {
    cursor: pointer;
    background: -webkit-linear-gradient(#ee7752, #e73c7e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 0;

    a {
      color: #fff;
    }

    a:hover {
      background-color: #000;
      background-image: linear-gradient(to right, #434343 0%, black 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.remember {
  position: relative;
  display: inline-block;
  color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 1050px) {
  #square0,
  #square1,
  #square2,
  #square3,
  #square4,
  #square5 {
    display: none;
  }
}
