#checked {
  width: 120px;
  height: 120px;
}
.thanks-heading {
  font-size: 32px;
  margin-top: 30px;
  line-height: 40px;
  font-family: "noto-sans", sans-serif;
}
#check-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-direction: column;
  text-align: center;
}

#check-container h2 {
  font-size: 30px;
  color: black;
  padding-top: 30px;
  width: 800px;
  font-weight: 400;
}

@media only screen and (max-width: 1000px) {
  #check-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;
    /* background-color: aquamarine; */
    text-align: center;
  }
  #check-container h2 {
    font-size: 28px;
    color: black;
    /* padding-top: 30px; */
    width: 500px;
    font-weight: 400;
  }
  #checked {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 600px) {
  #check-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    flex-direction: column;
    /* background-color: aquamarine; */
    text-align: center;
  }
  #check-container h2 {
    font-size: 20px;
    color: black;
    /* padding-top: 30px; */
    width: 300px;
    font-weight: 400;
  }
}
