.butt {
  width: 170px;
  height: 45px;
  font-weight: bold;
  border-radius: 8px;
  margin: 0 10px !important;
  color: white !important;
  background-color: black !important;
  font-family: "Nunito Sans", sans-serif;
}

@media only screen and (max-width: 600px) {
  .butt {
    width: 100px;
    height: 40px;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    margin: 0 5px !important;
    background-color: black;
    font-family: "Nunito Sans", sans-serif;
  }
}
