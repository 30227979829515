#maddie {
  width: 110px;
  height: 130px;
  object-fit: contain;
}
.service-price-heading {
  font-size: 22px;
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
  font-family: "noto-sans", sans-serif;
}

@media screen and (max-width: 650px) {
  .service-price-heading {
    font-size: 18px;
    margin-top: 20px;
    padding: 10px 15px 0px 15px;
    font-weight: bold;
    font-family: "noto-sans", sans-serif;
    text-align: center;
  }
}
