.coverImg {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.dashboardOpacity {
  width: 70%;
  border: 1px solid gray;
  height: calc(100vh - 250px);
  background-color: #ffffff;
  position: absolute !important;
  border-radius: 20px;
  border: none;
}
.dashboard-logo {
  width: 200px;
  margin-bottom: 30px;
  margin-left: 50px !important;
  position: relative !important;
}
.dashboard-main-container {
  width: 100%;
  position: relative;
  text-align: center;
  flex-direction: column;
  display: flex !important;
  height: calc(100vh - 68px);
  align-items: center !important;
  justify-content: center !important;
  border-radius: 20px;
}
.dashboardContainer {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  height: 60%;
}
.dashboard-heading {
  width: 480px;
  color: black;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 45px !important;
  position: relative !important;
  margin-bottom: 30px !important;
  font-family: "noto-sans", sans-serif !important;
}

@media screen and (max-width: 650px) {
  .dashboard-heading {
    width: 60%;
    color: black;
    font-size: 18px;
    overflow: hidden;
    text-transform: uppercase;
    line-height: 25px !important;
    position: relative !important;
    margin-bottom: 30px !important;
    font-family: "noto-sans", sans-serif !important;
  }
}

@media only screen and (max-width: 800px){
  .dashboardOpacity {
    width: 90%;
  }
  .dashboard-logo {
    width: 150px;
    margin-left: 30px !important;
  }
  .dashboard-heading {
    width: 380px;
    font-size: 24px;
    line-height: 35px !important;
    position: relative !important;
  }
}

@media only screen and (max-width: 440px){
  .dashboardOpacity {
    width: 95%;
  }
  .dashboard-logo {
    width: 150px;
    margin-bottom: 20px;
    margin-left: 30px !important;
  }
  .dashboard-heading {
    width: 320px;
    font-size: 20px;
    line-height: 30px !important;
    position: relative !important;
  }
}