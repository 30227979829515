#services {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
#header-services {
  width: 100%;
  padding: 10px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-bottom: 1px solid gainsboro;
}
#logo-services {
  width: 200px;
}
#service-title {
  width: 40%;
  color: black;
  font-size: 3.5em;
  text-align: center;
  font-weight: bolder;
  margin-bottom: 50px;
  font-family: "noto-sans", sans-serif;
}
#solar-title {
  width: 50%;
  color: black;
  font-size: 3.5em;
  text-align: center;
  font-weight: bolder;
  margin-bottom: 20px;
  font-family: "noto-sans", sans-serif;
}
#internet-title {
  color: black;
  font-size: 3em;
  line-height: 0.5em;
  font-weight: bold;
  text-align: center;
  font-family: "noto-sans", sans-serif;
}

#maddie {
  width: 150px;
  height: 130px;
  object-fit: contain;
}
#card-services {
  width: 100%;
  height: 150px;
  display: flex;
  margin: 0 10px;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 5px gainsboro;
}
#selected-card-services {
  width: 150px;
  height: 150px;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgb(0, 0, 0);
  box-shadow: 0 0 5px gainsboro;
}
#card-services-images {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
#card-services-title {
  font-weight: bold;
}
#card-services-desc {
  color: black;
  font-size: 1.8em;
  font-weight: 500;
  margin-bottom: 20px;
  font-family: noto-sans, sans-serif;
}
#card-services-row {
  width: 980px;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-between;
}
#card-services-internet {
  width: 80px;
  object-fit: contain;
}

#card-security-img {
  width: 100px;
  border-radius: 0em;
}
#card-pest-internet {
  width: 80px;
  border-radius: 0em;
}
#card-internet {
  width: 150px;
  height: 70px;
  margin: 10px;
  display: flex;
  cursor: pointer;
  box-shadow: none;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #efeff4;
}
#card-internet-img {
  width: 200px;
  height: 90px;
  display: flex;
  margin: 0 10px;
  cursor: pointer;
  box-shadow: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
#selected-card-internet {
  width: 150px;
  height: 70px;
  margin: 10px;
  display: flex;
  cursor: pointer;
  box-shadow: none;
  align-items: flex-start;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgb(0, 0, 0);
  background-color: #efeff4;
}

#card-internet-row {
  width: 650px;
  display: flex;
  margin-top: 100px;
  flex-direction: row;
  justify-content: space-between;
}

/* #card-services-internet {
  width: 80px;
  object-fit: contain;
} */
#card-security-row {
  width: 440px;
  display: flex;
  margin-top: 100px;
  flex-direction: row;
  justify-content: space-between;
}

.hideOnMobile {
  display: none !important;
}
.marginTopForRow {
  margin-top: 100px;
}
.marginTopOnRow {
  margin-top: 50px;
  justify-content: space-around;
}
.marginTopOnRow2 {
  display: flex;
  margin-top: 80px;
  min-height: 50px;
  justify-content: space-around;
}
.marginTopOnRow3 {
  margin-top: 50px;
  justify-content: space-around;
}
.marginTopOnRow4 {
  margin-top: 50px;
  justify-content: space-around;
}
#last-card-services {
  width: 150px;
  height: 160px;
  display: flex;
  margin: 0 10px;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 5px gainsboro;
}
@media only screen and (max-width: 600px) {
  .marginTopForRow {
    margin-top: 0px;
  }

  .marginTopOnRow {
    display: flex;
    margin-top: 120px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .marginTopOnRow2 {
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
  }
  .marginTopOnRow3 {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
  }
  .marginTopOnRow3 {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
  }
  .marginTopOnRow4 {
    display: flex;
    margin-top: 220px;
    align-items: center;
    justify-content: center;
  }
  #card-services-internet {
    width: 80%;
    object-fit: contain;
  }
  #card-internet {
    width: 30%;
    height: 80px;
    display: flex;
    margin-top: 20px;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 5px gainsboro;
  }
  #selected-card-internet {
    width: 30%;
    height: 80px;
    display: flex;
    cursor: pointer;
    margin-top: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 2px solid skyblue;
    box-shadow: 0 0 5px gainsboro;
  }
  .btn-round {
    margin-top: 650px;
  }
  #card-services {
    display: flex;
    width: 100px;
    height: 100px;
    flex-direction: column;
  }
  #maddie {
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-bottom: -20px;
  }
}
@media only screen and (max-width: 1550px) {
  #card-internet {
    width: 130px;
    height: 80px;
    display: flex;
    margin-top: 20px;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 5px gainsboro;
  }
  #selected-card-internet {
    width: 130px;
    height: 80px;
    display: flex;
    cursor: pointer;
    margin-top: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 2px solid skyblue;
    box-shadow: 0 0 5px gainsboro;
  }
}

@media only screen and (max-width: 600px) {
  #selected-card-internet {
    width: 44%;
    margin: 3%;
    border-radius: 5px;
  }
  #card-internet {
    margin: 3%;
    width: 44%;
    border-radius: 5px;
  }
  #card-services {
    width: 150px;
    height: 160px;
    display: flex;
    margin: 0 10px;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 5px gainsboro;
  }
  #last-card-services {
    width: 60px;
    height: 80px;
    display: flex;
    margin: 0 10px;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    /* background-color: green; */
    justify-content: center;
    box-shadow: 0 0 5px gainsboro;
  }
  #card-services-images {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
}
